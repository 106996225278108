exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-tag-tsx": () => import("./../../../src/pages/tag.tsx" /* webpackChunkName: "component---src-pages-tag-tsx" */),
  "component---src-templates-music-tsx": () => import("./../../../src/templates/music.tsx" /* webpackChunkName: "component---src-templates-music-tsx" */),
  "component---src-templates-musics-tsx": () => import("./../../../src/templates/musics.tsx" /* webpackChunkName: "component---src-templates-musics-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

